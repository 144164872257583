<template>
  <div>
  <div class="d-inline-flex card text-center justify-content-center w-75">
    <legend class="card-header">Submit to Feedbackker</legend>
    <p class="text-muted p-2">First, some basic information about what you're submitting, and where you're up to.</p>

    <div class="form-group p-2">
      <p for="link">Link</p>
      <div class="d-inline-flex p-4">
        <input id="link" name="link" type="text" maxlength="250" placeholder="link to the content" class="form-control d-inline-flex" required="" v-model="submission.link">
      </div>

    </div><br>

    <div class="form-group p-2">
      <p for="content_name">Submission Name</p>
      <div class="d-inline-flex p-4">
        <input id="content_name" name="content_name" type="text" maxlength="250" placeholder="name your submission" class="form-control d-inline-flex" required=""  v-model="submission.name">
      </div>
    </div><br>
    <!-- Select Basic -->
    <div class="form-group d-flex-inline p-2">
      <p for="age">Age Suitability</p>
        <div class="d-inline-flex p-4">
        <select id="age" name="age" class="form-control d-inline-flex" v-model="submission.ageRating">
          <option value="0">Suitable for all ages</option>
          <option value="1" v-if="censor >= 1">13+</option>
          <option value="2" v-if="censor >= 2">16+</option>
          <option value="3" v-if="censor >= 3">18+</option>
          <option value="4" v-if="censor >= 4">NSFW</option>
        </select>
        </div>
    </div><br>
    <!-- Select Basic -->
    <div class="form-group p-2">
      <p for="format">Which Format best describes this submission?</p>
      <div class="d-inline-flex p-4">
        <select id="format" name="format" class="form-control d-inline-flex" v-model="submission.format">
          <option value="Image">Image</option>
          <option value="Sound">Sound</option>
          <option value="Video">Video</option>
          <option value="Creative Writing">Creative Writing</option>
          <option value="Object">Object</option>
          <option value="Code">Code</option>
          <option value="Performance">Performance</option>
        </select>
      </div>
    </div><br>
    <!-- Select Basic -->
    <div class="form-group p-2">
      <p for="medium">Medium</p>
      <div class="d-inline-flex p-4">
        <select id="medium" name="medium" class="form-control d-inline-flex" v-model="submission.medium">
          <option value="Digital Art">Digital Art</option>
          <option value="Traditional Art">Traditional Art</option>
          <option value="Photograph">Photograph</option>
          <option value="Vector Art">Vector Art</option>
          <option value="Stationary CG">Stationary CG</option>
          <option value="Motion CG">Motion CG</option>
          <option value="VFX">VFX</option>
          <option value="Film">Film</option>
          <option value="Traditional Animation">Traditional Animation</option>
          <option value="Digital Animation">Digital Animation</option>
          <option value="Other Stationary Visual">Other Stationary Visual</option>
          <option value="Other Motion Visual">Other Motion Visual</option>
          <option value="Audio">Audio</option>
          <option value="Music">Music</option>
          <option value="Music - Transcription">Music - Transcription</option>
          <option value="Music - Performance">Music - Performance</option>
          <option value="Other Music or Audio">Other Music or Audio</option>
          <option value="Prose">Prose</option>
          <option value="Poetry">Poetry</option>
          <option value="Script or Screenplay">Script or Screenplay</option>
          <option value="Lyrics or Libretto">Lyrics or Libretto</option>
          <option value="Sculpture">Sculpture</option>
          <option value="Origami">Origami</option>
          <option value="Other Modelling">Other Modelling</option>
          <option value="Creative Coding">Creative Visual Coding</option>
          <option value="Creative Coding">Creative Audio Coding</option>
          <option value="Web Design">Web Design</option>
          <option value="Dance">Dance</option>
          <option value="Acting">Acting</option>
          <option value="Performance Art">Performance Art</option>
          <option value="Other Performance">Other Performance</option>
          <option value="Other">Other</option>
        </select>
      </div>
    </div><br>
    <!-- Select Basic -->
    <div class="form-group p-2">
      <p for="completion">Level of Completion</p>
      <div class="d-inline-flex p-4">
        <select id="completion" name="completion" class="form-control d-inline-flex" v-model="submission.completion">
          <option value="0">Vague Idea</option>
          <option value="1">Initial sketch or planning</option>
          <option value="2">Partial work-in-progress</option>
          <option value="3">Majority work-in-progress</option>
          <option value="4">Nearly complete work-in-progress</option>
          <option value="5">Partially edited/post-produced</option>
          <option value="6">Believed complete</option>
          <option value="7">Published and complete</option>
        </select>
      </div>
      </div>
  </div>

  <hr class="p-2 w-25">

  <div class="d-inline-flex card text-center justify-content-center w-75">
      <!-- Textarea -->
      <div class="form-group p-2">
        <h3 class="card-header">Focus</h3>
        <label class="control-label text-muted p-3" for="focus">Outline your goals for the work you're submitting.<br> Make sure your reviewers know exactly where you're coming from, and where you're going to.</label>
          <textarea class="form-control p-3 justify-content-center" id="focus" name="focus" rows="10" maxlength="2040" placeholder="What should the reviewers pay special attention to?" v-model="submission.focus"></textarea>
      </div>
  </div>

  <hr class="p-2 w-25">
  <div class="d-inline-flex card text-center justify-content-center w-75">
    <h3 class="card-header">Questions</h3>
    <div class="form-group p-2">
      <label class="control-label p-3" for="questions">Ask any specific questions you have for the reviewers here.</label>
        <textarea class="form-control p-3 justify-content-center" id="questions" name="questions" rows="10" maxlength="2040" placeholder="For example: I was trying to use three-point perspective, but I'm not sure it's worked. Can you recommend a tutorial?" v-model="submission.questions"></textarea>
    </div>
  </div>
  <hr class="p-2 w-25">
  <div class="d-inline-flex card text-center justify-content-center w-75">
    <h3 class="card-header">Finally...</h3>
    <p class="text-muted">Some last details to help our search algorithm get this submission to the right people.</p>
      <div class="form-group form-check">
        <label class="form-check-label" for="Check">Is it fan content?</label><br>
        <input type="checkbox" class="form-check-input justify-content-center" id="Check" value="true" v-model="submission.fan">
      </div>
      <div class="form-group p-2">
        <label class="control-label p-3" for="tags">Tags: separate tags by one comma (,) and a space.</label>
          <textarea class="form-control d-inline-flex" id="tags" name="tags"  rows="3" maxlength="250" placeholder="For example: Mona Lisa, Impressionist, Purple" v-model="submission.tags"></textarea>
      </div>
  </div>
      <!-- Button -->
      <div class="form-group p-2">
          <button type="submit" id="submit" name="submit" class="btn btn-primary justify-content-center" value="Submit" @click="submit">Submit</button>
      </div>
  </div>

</template>

<script>
export default {
  name: 'SubmitForm',
  props: {
    msg: String
  },
  data: () => ({
    censor: localStorage.atCensor,
    submission: {
      link: '',
      name: '',
      ageRating: '',
      format: '',
      medium: '',
      completion: '',
      focus: '',
      questions: '',
      fan: false,
      tags: ''
    }
  }),
  methods: {
    submit () {
      const submissionData = {
        link: this.submission.link,
        name: this.submission.name,
        ageRating: this.submission.ageRating,
        format: this.submission.format,
        medium: this.submission.medium,
        completion: this.submission.completion,
        focus: this.submission.focus,
        questions: this.submission.questions,
        fan: this.submission.fan,
        tags: this.submission.tags
      }
      this.$store.dispatch('feedbackkerSubmissions/makeSubmission', submissionData)
    }
  }

}
</script>
