<template>
  <div>
    <SubmitForm v-if="auth.atToken"/>
  </div>
</template>

<script>
// @ is an alias to /src
import SubmitForm from '@/components/SubmitForm.vue'

export default {
  name: 'Get',
  components: {
    SubmitForm
  },
  data: () => ({
    auth: {
      atToken: localStorage.getItem('atToken')
    }
  })
}

</script>
